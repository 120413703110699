import { useEffect, useCallback, useState } from 'react'
import { Form, Input, Select, Button, Row, Col, message, InputNumber, Skeleton, Grid } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { selectSelectList } from '../../../LoginPage/reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { AddSettlementFormState, AddSettlementPostDataState } from '../interfaces'
import OtpInput from 'react-otp-input'
import { getSettlementBankAccountList, selectSettlementBankAcountList, selectSettlementBankAcountListLoading, postAddSettlement, getSettlement, selectPostAddSettlementLoading } from '../reducers'
import { SettlementButtonsContainer } from '../styles'
import { selectGlobalCurrency } from '../../../AuthLayout/reducers'
import { datetime, getCurrencyValue, numberFormatter } from '../../../../utils/utils'

const { useBreakpoint } = Grid

const AddSettlementForm = (props: AddSettlementFormState): JSX.Element => {
  const { setAddVisible, filters } = props
  const dispatch = useAppDispatch()
  const screens = useBreakpoint()
  const selectList = useAppSelector(selectSelectList)
  const settlementBankAcountList = useAppSelector(selectSettlementBankAcountList)
  const settlementBankAcountListLoading = useAppSelector(selectSettlementBankAcountListLoading)
  const postAddSettlementLoading = useAppSelector(selectPostAddSettlementLoading)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  const [form] = Form.useForm()
  const [postDataBanks, setPostDataBanks] = useState<AddSettlementPostDataState>({
    bankId: undefined,
    currency: getCurrencyValue(globalCurrency),
    toMAccountId: undefined
  })
  const inputStyle = {
    fontSize: '16px',
    height: '32px',
    width: '35px',
    border: '1px solid #d9d9d9',
    borderRadius: '2px'
  }

  const bankSelectList = selectList.bankSelectList.filter(b => (postDataBanks.currency !== undefined && postDataBanks.currency > 0) ? b.currency === postDataBanks.currency : false)
    .map((item: { code: string, name: string, id: number }) => ({
      label: `${item.code} - ${item.name}`,
      value: item.id
    })
    ).sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  const merchantBankAccountSelectList = settlementBankAcountList.filter((x: { bankId: number }) => (postDataBanks.bankId !== undefined && postDataBanks.bankId > 0)
    ? x.bankId === postDataBanks.bankId : false).map((item: { bankAccountCode: string, name: string, id: number }) => (
    {
      label: `${item.bankAccountCode} - ${item.name}`,
      value: item.id
    })
  ).sort((a: { label: number }, b: { label: number }) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.label > b.label) ? 1 : -1) : -1)

  async function onHandleAddSettlementSubmit (values: any): Promise<any> {
    const addSettlementSubmitPayload = {
      bankId: values.bankId,
      currency: getCurrencyValue(globalCurrency),
      toMAccountId: values.toMAccountId,
      note: values.note,
      otp: values.otp === undefined ? '' : values.otp,
      requestedAmount: values.requestedAmount
    }
    const filtersPayload = {
      ...filters,
      startDate: datetime.getStartDate(filters.startDate),
      endDate: datetime.getStartDate(filters.endDate)
    }

    try {
      await dispatch(postAddSettlement(addSettlementSubmitPayload)).then(unwrapResult)
      await dispatch(getSettlement(filtersPayload))
      await setAddVisible(false)
      return await message.success('Add Settlement Success!')
    } catch (error: any) {
      return await message.error(error.message)
    }
  }

  function onHandleCancel (): void {
    setAddVisible(false)
  }

  function onHandleChangeBank (value: number): void {
    setPostDataBanks({
      ...postDataBanks,
      bankId: value
    })
  }

  const getSettlementBankAccountListAction = useCallback(async (): Promise<any> => {
    try {
      await dispatch(getSettlementBankAccountList()).then(unwrapResult)
    } catch (error: any) {
      return await message.error(error.message)
    }
  }, [dispatch])

  useEffect(() => {
    getSettlementBankAccountListAction().finally(() => {})
  }, [getSettlementBankAccountListAction])

  return (
    <>
      {
        (settlementBankAcountListLoading && <Skeleton active />) ||
          <Form
            name='add-settlement-form'
            onFinish={onHandleAddSettlementSubmit}
            requiredMark={false}
            layout='vertical'
            form={form}
            initialValues={{
              bankId: undefined,
              currency: undefined,
              note: '',
              otp: '',
              requestedAmount: '',
              toMAccountId: undefined
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Bank'
                  name='bankId'
                  rules={[{ required: true, message: 'Bank is required.' }]}
                >
                  <Select
                    placeholder='Select Bank'
                    options={bankSelectList}
                    value={postDataBanks.bankId}
                    onChange={onHandleChangeBank}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Beneficiary Name'
                  name='toMAccountId'
                  rules={[{ required: true, message: 'Beneficiary Name is required.' }]}
                >
                  <Select
                    placeholder='Select Beneficiary'
                    options={merchantBankAccountSelectList}
                    loading={settlementBankAcountListLoading}
                    allowClear
                    showSearch
                    filterOption={(input, option: any) => {
                      return option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }}

                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Requested Amount'
                  name='requestedAmount'
                  rules={[{ required: true, message: 'Requested Amount is required.' }]}
                >
                  <InputNumber
                    formatter={numberFormatter}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                    placeholder='Requested Amount'
                    stringMode
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Note'
                  name='note'
                >
                  <Input placeholder='Note' />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label='Verification Code'
                  name='otp'
                  rules={[
                    { required: true, message: 'Otp is required.' },
                    { min: 6, message: 'Otp is required.' },
                    { max: 6, message: 'Otp is required.' }
                  ]}
                >
                  <OtpInput
                    numInputs={6}
                    separator={<span>&nbsp;</span>}
                    isInputNum
                    inputStyle={inputStyle}
                  />
                </Form.Item>
              </Col>
            </Row>
            <SettlementButtonsContainer>
              <Button
                onClick={onHandleCancel}
                shape='round'
                block={screens.xs}
              >
                Cancel
              </Button>
              <Button
                htmlType='submit'
                shape='round'
                type='primary'
                block={screens.xs}
                loading={postAddSettlementLoading}
              >
                Add
              </Button>
            </SettlementButtonsContainer>
          </Form>
      }
    </>
  )
}

export default AddSettlementForm
